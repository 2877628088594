export default() => ({
    init() {
        // swiper element
        const swiperEl = document.querySelector('.testimonial-swiper');
        if (swiperEl) {
            // swiper parameters
            const swiperParams = {
                slidesPerView: 1,
                spaceBetween: 50,
                breakpoints: {
                    640: {
                        slidesPerView: 2,
                    },
                    1024: {
                        slidesPerView: 3,
                    },
                },
                on: {
                    init() {
                        // ...
                    },
                },
            };

            // now we need to assign all parameters to Swiper element
            Object.assign(swiperEl, swiperParams);

            // and now initialize it
            swiperEl.initialize();
        }
    }
})
