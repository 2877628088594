import {getDistance} from "geolib";

export default () => ({
    map: null,
    locations,
    markers: [],
    shop: "rossmann",
    originLocation: null,
    hasUserAddress: false,

    init() {
        window.initMap = async () => {
            let map = new google.maps.Map(document.getElementById("map"), {
                center: {lat: 47.2, lng: 19.0833},
                zoom: 7,
                gestureHandling: "cooperative",
            });

            this.map = map;
            await this.selectShop(this.shop);
            this.spinner_off();

            this.map.data.addListener('click', (event) => {
                this.showDetails(event.feature, this.map);
            });

            const input = document.getElementById('pac-input');
            const options = {
                types: ['address'],
                componentRestrictions: {country: 'hu'}
            };

            const autocomplete = new google.maps.places.Autocomplete(input, options);

            autocomplete.setFields(
                ['address_components', 'geometry', 'name']);

            // Set the origin point when the user selects an address
            const originMarker = new google.maps.Marker({map: map});
            originMarker.setVisible(false);
            this.originLocation = map.getCenter();

            autocomplete.addListener('place_changed', async () => {
                originMarker.setVisible(false);
                this.originLocation = map.getCenter();
                const place = autocomplete.getPlace();

                if (!place.geometry) {
                    // User entered the name of a Place that was not suggested and
                    // pressed the Enter key, or the Place Details request failed.
                    // window.alert('No address available for input: \'' + place.name + '\'');
                    return;
                }

                // Recenter the map to the selected address
                this.originLocation = place.geometry.location;
                map.setCenter(this.originLocation);
                map.setZoom(14);

                originMarker.setPosition(this.originLocation);
                originMarker.setVisible(false);

                // Use the selected address as the origin to calculate distances
                // to each of the store locations
                this.hasUserAddress = true;
                const rankedStores = await this.calculateDistances(map.data, this.originLocation);
                this.showStoresListWithDistance(map.data, rankedStores);
            });
            this.markers.push(originMarker);
        }

        function startup() {
            let script = document.createElement('script');
            script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAWFPIM_qAarOxLe4raYWf1UInQDO2gODw&libraries=places&callback=initMap';
            script.async = true;

            document.head.appendChild(script);
        }

        startup();
    },

    async fetchLocations(shop) {
        const res = await fetch(`https://geoservice.promoc.io/api/${shop}`);
        const json = await res.json();

        return json;
    },

    async calculateDistances(data, origin) {
        let distances = [];

        data.forEach((store) => {
            const storeNum = store.getProperty('storeid');
            const storeLoc = store.getGeometry().get();

            let distance = getDistance(
                {
                    latitude: origin.lat(),
                    longitude: origin.lng()
                },
                {
                    latitude: storeLoc.lat(),
                    longitude: storeLoc.lng()
                }
            );

            distances.push({
                storeid: storeNum,
                distanceText: (distance / 1000).toFixed(1) + " km",
                distanceVal: distance,
            });
        });

        distances.sort((first, second) => {
            return first.distanceVal - second.distanceVal;
        });

        return distances;
    },

    appendLink(title_text, text, location, container) {
        const title_element = document.createElement('h5');
        title_element.innerText = title_text;
        container.appendChild(title_element);

        const link = document.createElement('a');
        link.innerText = text;
        link.href = location;
        container.appendChild(link);
    },

    appendParagraph(title_text, text, container) {
        if (title_text.length > 0) {
            const title_element = document.createElement('h5');
            title_element.innerText = title_text;
            container.appendChild(title_element);
        }
        const text_element = document.createElement('p');
        text_element.innerText = text;
        container.appendChild(text_element);
    },

    prependBackLink(container) {
        const link = document.createElement('a');
        link.innerText = 'Vissza';

        link.addEventListener('click', () => {
            this.showStoresList(this.map.data);
        });

        container.prepend(link);
    },

    appendDetailsLink(container, feature) {
        const link = document.createElement('a');
        link.innerText = 'Részletek';
        link.feature = feature;

        link.addEventListener('click', (event) => {
            this.showDetails(event.target.feature);
        });

        container.appendChild(link);
    },

    appendTitle(title_text, container) {
        const title = document.createElement('h4');
        title.innerText = title_text;
        container.appendChild(title);
    },

    getLocationObj(feature) {
        return {
            storeid: feature.getProperty('storeid'),
            name: feature.getProperty('name'),
            phone: feature.getProperty('phone'),
            email: feature.getProperty('email'),
            address: feature.getProperty('address'),
            link: feature.getProperty('url'),
            hours: feature.getProperty('hours'),
            coordinates: feature.getProperty('coordinates'),
        };
    },

    showDetails(feature, map) {
        const location = this.getLocationObj(feature);
        let link = location.link;

        if (this.shop === 'dm') {
            link = 'https://www.dm.hu/dologel-foginy-gel-p3518646058844.html'; // dm webshop link
        } else if (this.shop === 'rossmann') {
            link = 'https://shop.rossmann.hu/termek/dologel-foginygel-25-ml'; // rossmann webshop link
        }

        const contentString = `
            <div id="content">
                <h1>${location.address}</h1>
            </div>
        `;

        let infowindow = new google.maps.InfoWindow({
            content: contentString,
            ariaLabel: "Shop location",
        });

        let marker = new google.maps.Marker({
            position: {lat: location.coordinates[0], lng: location.coordinates[1]},
            map,
        });

        infowindow.open({
            anchor: marker,
            map
        });

        this.map.setCenter({lat: location.coordinates[0], lng: location.coordinates[1]});
        this.map.setZoom(14);
    },
    showStoresListWithDistance(data, stores) {
        let parent = document.getElementById('locations');

        parent.replaceChildren();

        stores.forEach((store) => {
            const container_div = document.createElement('div');
            const currentStore = data.getFeatureById(store.storeid);

            let name = currentStore.getProperty('name');
            let link = currentStore.getProperty('link');

            if (name && name.length > 0) {
                this.appendTitle(name, container_div);
                this.appendParagraph('', currentStore.getProperty('address'), container_div);
                this.appendParagraph('Távolság:', store.distanceText, container_div);

                this.appendDetailsLink(container_div, currentStore);
            } else {
                this.appendTitle(currentStore.getProperty('address'), container_div);
                this.appendParagraph('Távolság:', store.distanceText, container_div);
                this.appendDetailsLink(container_div, currentStore);
                this.appendWebshopLink(container_div, currentStore, this.shop); // add this line
            }

            if (link && link.length > 0) {
                this.appendPatikaLink(container_div, currentStore, link);
            }

            parent.appendChild(container_div);
        });
    },

    appendWebshopLink(container, feature, shop) {
        const link = document.createElement('a');
        link.innerText = 'Tovább a webshopra';
        link.setAttribute("target", "_blank");
        link.classList.add('to-webshop', 'shop-button', 'selected');
        if (shop === 'dm') {
            link.classList.add('to-webshop-dm');
            link.href = 'https://www.dm.hu/dologel-foginy-gel-p3518646058844.html'; // dm webshop link
        } else if (shop === 'rossmann') {
            link.classList.add('to-webshop-rossmann');
            link.href = 'https://shop.rossmann.hu/termek/dologel-foginygel-25-ml'; // rossmann webshop link
        }

        container.appendChild(link);
    },

    appendPatikaLink(container, feature, linkStr) {
        const link = document.createElement('a');
        link.innerText = 'Tovább a patika oldalára';
        link.setAttribute("target", "_blank");
        link.classList.add('to-webshop', 'shop-button', 'selected');
        link.href = linkStr;

        container.appendChild(link);
    },


    showStoresList(data) {
        let parent = document.getElementById('locations');

        parent.replaceChildren();

        this.locations.features.forEach((store) => {
            const container_div = document.createElement('div');

            let currentStore = data.getFeatureById(store.properties.storeid);
            let name = currentStore.getProperty('name');
            let link = currentStore.getProperty('link');

            if (name && name.length > 0) {
                this.appendTitle(name, container_div);
                this.appendParagraph('', currentStore.getProperty('address'), container_div);

                this.appendDetailsLink(container_div, currentStore);
            } else {
                this.appendTitle(currentStore.getProperty('address'), container_div);
                this.appendDetailsLink(container_div, currentStore);
                this.appendWebshopLink(container_div, currentStore, this.shop); // add this line
            }

            if (link && link.length > 0) {
                this.appendPatikaLink(container_div, currentStore, link);
            }

            parent.appendChild(container_div);
        });
    },

    spinner_off() {
        let blur_container = document.getElementById("blur-container");

        blur_container.classList.add('hidden');
    },
    async selectShop(shop) {
        this.shop = shop;

        this.markers.forEach((marker) => {
            marker.setMap(null);
        });

        this.locations = await this.fetchLocations(this.shop);

        this.map.data.addGeoJson(this.locations, {idPropertyName: 'storeid'})

        if (!this.hasUserAddress) {
            this.showStoresList(this.map.data);
        } else {
            const rankedStores = await this.calculateDistances(this.map.data, this.originLocation);
            this.showStoresListWithDistance(this.map.data, rankedStores)
        }
    },
})
