import Alpine from 'alpinejs'
import Swiper, { Navigation, Pagination } from 'swiper';
import { register } from 'swiper/element/bundle';

import '../style/main.scss';
import header from "./header";
import testimonials from "./testimonials";
import locationFinder from "./location-finder";
import scrollTo from 'alpinejs-scroll-to';

register();

Alpine.plugin(scrollTo)

window.Swiper = Swiper;
window.Alpine = Alpine

Alpine.data('header',header);
Alpine.data('testimonials',testimonials);
Alpine.data('locationFinder',locationFinder);

Alpine.start()
