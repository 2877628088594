export default () => ({
    mobileMenuVisible: false,
    hasBackground: false,
    scrollTopOffset: 10,
    init() {

    },
    toggleMenu() {
        this.mobileMenuVisible = !this.mobileMenuVisible;
    },
    onScroll() {
        const scrollTop = window.scrollY;
        this.hasBackground = scrollTop > this.scrollTopOffset;
    },
    scrollTo(isHome, el, event) {
        console.log(isHome)
        console.log(el)
        console.log(event)

        if (isHome) {
            event.preventDefault();
        }

        this.mobileMenuVisible = false;
        let targetId = el.getAttribute('href').replace('/#', '');
        const scrollToEl = document.getElementById(targetId);
        const scrollToElPos = scrollToEl.getBoundingClientRect();

        let scrollOffset = 0
        scrollOffset = document.querySelector('[x-scroll-to-header]').clientHeight

        window.scrollTo({
            top: scrollToElPos.top + document.documentElement.scrollTop - scrollOffset,
            left: 0,
            behavior: 'smooth',
        })
    }

})
